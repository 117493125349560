import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { resetSelectedItemsAction, selectedItemsCountSelector } from '@air/redux-selected-items';
import produce from 'immer';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { BulkDownloadAssetsModal } from '~/components/Modals/BulkDownloadAssetsModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useBulkDownloadPublicAssets } from '~/hooks/useBulkDownloadPublicAssets';
import {
  selectedBoardsIdsSelector,
  selectedBoardsSelector,
  selectedClipIdsSelector,
  selectedClipItemsSelector,
} from '~/store/selectedItems/selectors';
import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

import { useDownloadPublicItems } from './useDownloadPublicItems';
import { useGetPublicCustomFieldsMenuOptions } from './useGetCustomFieldsMenuOptions';

export const usePublicSelectionMenuOptions = () => {
  const { permissions } = usePublicPermissions();
  const { getCustomFieldsMenuOptions } = useGetPublicCustomFieldsMenuOptions();

  const { downloadPublicItems } = useDownloadPublicItems();
  const store = useAirStore();

  const canDownload = canGenerateZip(permissions);
  const canEditCustomFields = canChangeBoardCustomFields(permissions);

  const [showBulkDownloadAssetsModal] = useAirModal(BulkDownloadAssetsModal);
  const dispatch = useDispatch();
  const { bulkDownloadPublicAssets } = useBulkDownloadPublicAssets();
  const { shortId } = useShortIdContext();

  const getSelectionMenuOptions: GetSelectionMenuOptionsType = useCallback(
    (trackLocation) => {
      const selectedClips = selectedClipItemsSelector(store.getState());
      const selectedClipIds = selectedClipIdsSelector(store.getState());
      const selectedBoards = selectedBoardsSelector(store.getState());
      const selectedBoardsIds = selectedBoardsIdsSelector(store.getState());
      const selectedCount = selectedItemsCountSelector(store.getState());

      const options = produce<DropdownMenuOption[]>([], (draft) => {
        if (canDownload) {
          draft.push({
            ...getDropdownMenuOption('download'),
            onSelect: () => {
              if (selectedBoardsIds.length > 0) {
                downloadPublicItems({ clipIds: selectedClipIds, boardIds: selectedBoardsIds, trackLocation });
              } else {
                showBulkDownloadAssetsModal({
                  clipIds: selectedClipIds,
                  onDownloadAsZip: () => {
                    downloadPublicItems({ clipIds: selectedClipIds, trackLocation });
                    dispatch(resetSelectedItemsAction());
                  },
                  onDownloadIndividually: () => {
                    bulkDownloadPublicAssets({
                      clipIds: selectedClipIds,
                      shortId,
                      trackLocation,
                    });
                    dispatch(resetSelectedItemsAction());
                  },
                });
              }
            },
            type: 'item',
          });
        }

        if (canEditCustomFields) {
          const customFieldsMenuOptions = getCustomFieldsMenuOptions({
            boards: selectedBoards,
            assets: selectedClips.map((s) => s.item),
          });
          if (customFieldsMenuOptions.length) {
            draft.splice(3, 0, {
              ...getDropdownMenuOption('edit-custom-fields'),
              options: customFieldsMenuOptions,
              type: 'sub',
            });
          }
        }

        if (!!draft.length && selectedCount > 1) {
          draft.unshift(
            {
              id: 'selected-header',
              type: 'title',
              label: `${pluralize('item', selectedCount, true)} selected`,
            },
            {
              id: 'selected-separator',
              type: 'separator',
            },
          );
        }

        return draft;
      });

      return options;
    },
    [
      store,
      canDownload,
      canEditCustomFields,
      downloadPublicItems,
      getCustomFieldsMenuOptions,
      showBulkDownloadAssetsModal,
      dispatch,
      bulkDownloadPublicAssets,
      shortId,
    ],
  );

  return {
    getSelectionMenuOptions,
  };
};
