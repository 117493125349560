import { type GetBoardStatsResponse } from '@air/api';
import { formatBytes } from '@air/utilities';
import pluralize from 'pluralize';
import { useCallback } from 'react';

import { BoardStatsBarStat } from '~/_components/BoardStatsBar';

export const useFormatBoardStats = () => {
  const formatBoardStats = useCallback((data?: GetBoardStatsResponse) => {
    const statsArray: BoardStatsBarStat[] = [];

    if (data?.assetCount) {
      statsArray.push({
        id: 'assets',
        label: pluralize('asset', data.assetCount, true),
        value: data.assetCount.toString(),
      });
    }

    if (data?.fileCount) {
      statsArray.push({
        id: 'files',
        label: pluralize('file', data.fileCount, true),
        value: data.fileCount.toString(),
      });
    }

    if (data?.sizeBytes) {
      statsArray.push({
        id: 'size',
        label: formatBytes(data.sizeBytes),
        value: data.sizeBytes.toString(),
      });
    }

    return statsArray;
  }, []);

  return { formatBoardStats };
};
