import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { Clip } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useCallback } from 'react';

import { BulkDownloadFiles, isS3DownloadUrlActive } from '~/utils/DownloadFile';

export type BulkDownloadPublicAssetsParams = {
  clipIds: Clip['id'][];
  trackLocation: AirActionTrackingLocation;
  shortId: string;
};

export const useBulkDownloadPublicAssets = () => {
  const { showToast } = useToasts();
  const { trackDownloadedItems } = useTrackDownloadedItems();

  const bulkDownloadPublicAssets = useCallback(
    ({ clipIds, shortId, trackLocation }: BulkDownloadPublicAssetsParams) => {
      const urls = clipIds.map(async (id) => {
        const { url } = await ShortUrl.getDownloadUrl(shortId, id);

        return url;
      });

      Promise.all(urls)
        .then((urls) => {
          const activeUrls = urls.filter((url) => isS3DownloadUrlActive(url));

          BulkDownloadFiles(activeUrls);

          if (activeUrls.length < urls.length) {
            showToast('One or more of the selected file downloads has expired.');
          }
        })
        .catch((error) => {
          showToast('There was an error downloading a public asset.');

          reportErrorToBugsnag({
            error,
            context: 'Failed to download public assets from selection menu',
            metadata: {
              data: {
                clipIds,
                location: trackLocation,
              },
            },
          });
        });

      trackDownloadedItems({
        clipIds,
        location: trackLocation,
      });
    },
    [showToast, trackDownloadedItems],
  );

  return { bulkDownloadPublicAssets };
};
